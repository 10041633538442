import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivationEnd, UrlTree } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { TourService } from "ngx-ui-tour-md-menu";
import { Constants } from "../../constants/constants";
import { TourSteps } from "src/app/constants/tour-steps";
import { MixpanelService } from "src/app/services/mixpanel.service";

@Component({
    selector: "app-grids",
    templateUrl: "./grids.component.html"
})
export class GridsComponent implements OnInit, OnDestroy {
    activeTabUrl: string;
    urls = Constants.urls;
    private routeSubscription: Subscription;

    private tourSteps = TourSteps.grids;

    constructor(private router: Router, public tourService: TourService, public mixpanelService: MixpanelService) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                if (event.snapshot.routeConfig && event.snapshot.routeConfig.path) {
                    // Set active tab based on route event
                    if (event.snapshot.routeConfig.path !== Constants.urls.grids)
                        this.activeTabUrl = event.snapshot.routeConfig.path + "-tab";
                    else this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid_list]);
                }
            });
    }

    ngOnInit() {
        const currentUrl: UrlTree = this.router.parseUrl(this.router.url);
        if (
            currentUrl &&
            currentUrl.root &&
            currentUrl.root.children &&
            currentUrl.root.children.primary &&
            currentUrl.root.children.primary.segments[1] &&
            currentUrl.root.children.primary.segments[1].path
        ) {
            this.activeTabUrl = currentUrl.root.children.primary.segments[1].path + "-tab";
        } else {
            this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid_list]);
        }

        this.tourService.initialize(this.tourSteps);
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }
}
