import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { VisualizationObject } from "../drop-container/visualization-node";
import { BehaviorSubject } from "rxjs";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { TranslateService } from "@ngx-translate/core";
import { KeyMap } from "src/app/models/shared";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { OBJECT_TYPE } from "@zixi/models";

export const DRAG_EVENT_DATA_KEY = "grid-visualization/visualization-object";

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html"
})
export class SidebarComponent implements OnChanges {
    @Input() visualizationObjects: VisualizationObject[];
    private visualizationObjectsBs$ = new BehaviorSubject<VisualizationObject[]>([]);
    tableSchema: TableSchema[] = [
        {
            header: this.translateService.instant("TYPE"),
            columnDef: "type",
            visible: true,
            sticky: 1,
            width: 100,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<VisualizationObject>>(
                row => row.type,
                this.getTypeTitle.bind(this),
                (): boolean => true
            ),
            sortBy: (row: KeyMap<VisualizationObject>): string => row.type
        },
        {
            header: this.translateService.instant("NAME"),
            columnDef: "name",
            visible: true,
            width: 200,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<VisualizationObject>>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: (row: KeyMap<VisualizationObject>) => row.name
        }
    ];

    constructor(private translateService: TranslateService) {}

    dragRowHandler({ row, dragEvent }: { row: VisualizationObject; dragEvent: DragEvent }) {
        dragEvent.dataTransfer.setData(DRAG_EVENT_DATA_KEY, JSON.stringify(row));
        dragEvent.dataTransfer.effectAllowed = "move";
    }

    getTypeTitle(visualizationObject: VisualizationObject) {
        return visualizationObject.type === OBJECT_TYPE.GRID
            ? this.translateService.instant("GRID")
            : visualizationObject.type === OBJECT_TYPE.GRID_GROUP
            ? this.translateService.instant("GRID_GROUP")
            : "";
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.visualizationObjects.currentValue !== changes.visualizationObjects.previousValue) {
            this.visualizationObjectsBs$.next(this.visualizationObjects);
        }
    }

    get visualizationObjects$() {
        return this.visualizationObjectsBs$.asObservable();
    }
}
