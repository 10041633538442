import { Component, OnInit, OnDestroy } from "@angular/core";
import { Location, TitleCasePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";
import { Constants } from "../../../constants/constants";
import { GridsService } from "../grids.service";
import { UsersService } from "../../account-management/users/users.service";
import { GridGroup } from "../grid-group";
import { Grid } from "../grid";
import { SharedService } from "src/app/services/shared.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { TourService, IStepOption } from "ngx-ui-tour-md-menu";
import { TourSteps } from "src/app/constants/tour-steps";
import { MixpanelService } from "src/app/services/mixpanel.service";

@Component({
    selector: "app-grid-group-form",
    templateUrl: "./grid-group-form.component.html",
    providers: [TitleCasePipe]
})
export class GridGroupFormComponent implements OnInit, OnDestroy {
    gridGroup: GridGroup;
    gridGroupID: string;
    action: string;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    isAdmin: boolean;
    isZixiAdmin: boolean;
    isZixiSupport: boolean;
    isZixiSupportWrite: boolean;

    loading = true;
    saving = false;
    constants = Constants;

    privateGrids: Grid[];
    publicGrids: Grid[];

    currentSection = "info";

    private isAdminSubscription: Subscription;
    private isZixiAdminSubscription: Subscription;
    private isZixiSupportSubscription: Subscription;
    private isZixiSupportWriteSubscription: Subscription;

    private tourSteps = TourSteps.gridGroupForm;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private gs: GridsService,
        private userService: UsersService,
        private sharedService: SharedService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe,
        public tourService: TourService,
        public mixpanelService: MixpanelService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.gridGroupID = params.get("id");
            this.action = params.get("action");
            if (this.gridGroupID) {
                this.gridGroup = Object.assign({}, this.gs.getCachedGridGroup(parseInt(this.gridGroupID, 10)));
                // Check if grid group found in cache
                if (this.sharedService.isEmptyObject(this.gridGroup)) {
                    this.gs
                        .refreshGridGroups(true)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.gridGroup = Object.assign(
                                {},
                                this.gs.getCachedGridGroup(parseInt(this.gridGroupID, 10))
                            );
                            this.prepForm();
                            this.loading = false;
                        });
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        });
    }

    prepForm() {
        // Set Title
        this.titleService.setTitle(
            this.translate.instant("GRID_GROUP") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.gridGroup && this.gridGroup.name ? this.gridGroup.name : "")
        );

        if (this.action) {
            if (this.action === "edit") this.isEdit = true;
            if (this.action === "clone") {
                this.isClone = true;
                this.gridGroup.name = null;
            }
            this.privateGrids = _.filter(this.gridGroup.grids, ["public", 0]);
            this.publicGrids = _.filter(this.gridGroup.grids, ["public", 1]);
        }

        if (!this.gridGroup && !this.isClone && !this.isEdit) {
            this.gridGroup = new GridGroup();
            this.gridGroup.mode = "stack";
            this.gridGroup.cycle_pagination_interval = 15;
        }
    }

    ngOnInit() {
        this.isAdminSubscription = this.userService.isAdmin.subscribe(bool => {
            this.isAdmin = bool;
        });

        this.isZixiAdminSubscription = this.userService.isZixiAdmin.subscribe(bool => {
            this.isZixiAdmin = bool;
        });

        this.isZixiSupportSubscription = this.userService.isZixiSupport.subscribe(bool => {
            this.isZixiSupport = bool;
        });

        this.isZixiSupportWriteSubscription = this.userService.isZixiSupportWrite.subscribe(bool => {
            this.isZixiSupportWrite = bool;
        });

        this.prepForm();

        this.tourService.initialize(this.tourSteps);
    }

    ngOnDestroy() {
        this.isAdminSubscription.unsubscribe();
        this.isZixiAdminSubscription.unsubscribe();
        this.isZixiSupportSubscription.unsubscribe();
        this.isZixiSupportWriteSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.gridGroup.name,
            public: this.gridGroup.public || false,
            grid_ids: [].concat(_.map(this.privateGrids, "id"), _.map(this.publicGrids, "id")),
            mode: this.gridGroup.mode,
            cycle_pagination: this.gridGroup.cycle_pagination,
            cycle_pagination_interval: this.gridGroup.cycle_pagination_interval
        };

        if (this.isEdit) {
            const result = await this.gs.updateGridGroup(this.gridGroup, model);
            if (result) {
                this.saving = false;
                this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid_group, this.gridGroup.id]);
            } else this.saving = false;
        } else {
            const result = await this.gs.addGridGroup(model);
            if (result) {
                this.saving = false;
                this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid_group, result.id]);
            } else this.saving = false;
        }
    }

    cancel() {
        this.location.back();
    }

    // Error Code
    infoHasErrors(form: NgForm) {
        if (form.form.controls.name && form.form.controls.name.invalid === true) return true;
        else return false;
    }

    filterHasErrors() {
        return false;
    }

    displayHasErrors(form: NgForm) {
        if (
            form.form.controls.cycle_pagination_interval &&
            form.form.controls.cycle_pagination_interval.invalid === true
        )
            return true;
        return false;
    }

    // Scroll Spy Code
    onSectionChange(section: string) {
        this.currentSection = section;
    }

    scrollTo(section: string) {
        document.querySelector("#" + section).scrollIntoView({ behavior: "smooth", block: "start" });
    }
}
