import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxSliderModule } from "ngx-slider-v2";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { GridsComponent } from "./grids.component";
import { GridListComponent } from "./grid-list/grid-list.component";
import { GridGroupListComponent } from "./grid-group-list/grid-group-list.component";
import { GridFormComponent } from "./grid-form/grid-form.component";
import { GridGroupFormComponent } from "./grid-group-form/grid-group-form.component";
import { GridDetailComponent } from "./grid-detail/grid-detail.component";
import { GridGroupDetailComponent } from "./grid-group-detail/grid-group-detail.component";
import { GridContentComponent } from "./grid-detail/grid-content/grid-content.component";
import { ZxGridComponent } from "../../components/shared/zx-grid/zx-grid.component";
import { GridVisualizationComponent } from "./grid-visualization/grid-visualization.component";
import { SidebarComponent } from "./grid-visualization/sidebar/sidebar.component";

// Routes
import { GridsRouting } from "./grids.routing";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgxSliderModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        GridsRouting,
        TourMatMenuModule,
        MatTooltipModule
    ],
    declarations: [
        GridsComponent,
        GridListComponent,
        GridGroupListComponent,
        GridFormComponent,
        GridGroupFormComponent,
        GridDetailComponent,
        GridGroupDetailComponent,
        GridContentComponent,
        ZxGridComponent,
        GridVisualizationComponent,
        SidebarComponent
    ]
})
export class GridsModule {}
