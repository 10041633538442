import { Grid } from "./grid";

export class GridGroup {
    _frontData?: {
        sortableStatus: string;
    };
    created_at: string;
    customer_id: number;
    cycle_pagination: number;
    cycle_pagination_interval: number;
    grids: Grid[];
    id: number;
    mode: string;
    name: string;
    public: number;
    updated_at: string;
    user_id: number;
    fit_screen: number;
    hasFullDetails: boolean;
    layout_settings: string;
}
