import { Component, OnInit } from "@angular/core";
import { Location, TitleCasePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm, UntypedFormControl, Validators } from "@angular/forms";
import { take } from "rxjs/operators";
import * as _ from "lodash";
import { Constants } from "../../../constants/constants";
import { GridsService } from "../grids.service";
import { UsersService } from "../../account-management/users/users.service";
import { Grid } from "../grid";
import { SharedService } from "src/app/services/shared.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { TourService, IStepOption } from "ngx-ui-tour-md-menu";
import { TourSteps } from "src/app/constants/tour-steps";
import { MixpanelService } from "src/app/services/mixpanel.service";

@Component({
    selector: "app-grid-form",
    templateUrl: "./grid-form.component.html",
    providers: [TitleCasePipe]
})
export class GridFormComponent implements OnInit {
    grid: Grid;
    gridID: string;
    action: string;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    isAdmin: boolean;
    isZixiAdmin: boolean;
    isZixiSupportWrite: boolean;
    type = "status";

    loading = true;
    saving = false;
    constants = Constants;

    mutedObjects: number;
    acknowledgedObjects: number;
    selectedStatuses = [];

    statusList = [
        { name: "Error", id: "show_error" },
        { name: "Warning", id: "show_warning" },
        { name: "OK", id: "show_ok" },
        { name: "Pending", id: "show_pending" },
        { name: "Disabled", id: "show_disabled" }
    ];

    currentSection = "info";
    thumbnailRefreshSeconds = 8;

    includeTagsControl = new UntypedFormControl([], []);
    excludeTagsControl = new UntypedFormControl([], []);

    private tourSteps = TourSteps.gridForm;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private gs: GridsService,
        private userService: UsersService,
        private sharedService: SharedService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe,
        public tourService: TourService,
        public mixpanelService: MixpanelService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.gridID = params.get("id");
            this.action = params.get("action");
            //
            if (this.gridID) {
                this.grid = Object.assign({}, this.gs.getCachedGrid(parseInt(this.gridID, 10)));
                // Check if grid found in cache
                if (this.sharedService.isEmptyObject(this.grid)) {
                    this.gs
                        .refreshGrids(true)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.grid = Object.assign({}, this.gs.getCachedGrid(parseInt(this.gridID, 10)));
                            this.prepForm();
                        });
                } else this.prepForm();
            } else this.prepForm();
        });
    }

    prepForm() {
        // Set Title
        this.titleService.setTitle(
            this.translate.instant("GRID") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.grid && this.grid.name ? this.grid.name : "")
        );

        if (this.action) {
            if (this.action === "edit") this.isEdit = true;
            if (this.action === "clone") {
                this.isClone = true;
                this.grid.name = null;
            }

            if (this.grid.thumbnails) this.type = "thumbnails";

            if (this.grid.include_muted && this.grid.include_not_muted) this.mutedObjects = 0;
            else if (this.grid.include_muted) this.mutedObjects = 2;
            else if (this.grid.include_not_muted) this.mutedObjects = 1;

            if (this.grid.include_acknowledged && this.grid.include_not_acknowledged) this.acknowledgedObjects = 0;
            else if (this.grid.include_acknowledged) this.acknowledgedObjects = 2;
            else if (this.grid.include_not_acknowledged) this.acknowledgedObjects = 1;

            if (this.grid.show_disabled) this.selectedStatuses.push("show_disabled");
            if (this.grid.show_error) this.selectedStatuses.push("show_error");
            if (this.grid.show_ok) this.selectedStatuses.push("show_ok");
            if (this.grid.show_pending) this.selectedStatuses.push("show_pending");
            if (this.grid.show_warning) this.selectedStatuses.push("show_warning");

            if (!this.grid.cycle_pagination_interval || this.grid.cycle_pagination_interval === 0)
                this.grid.cycle_pagination_interval = null;

            if (this.grid.thumbnails_refresh_interval)
                this.thumbnailRefreshSeconds = this.grid.thumbnails_refresh_interval / 1000;

            this.includeTagsControl.setValue(this.grid.includeResourceTags);
            this.excludeTagsControl.setValue(this.grid.excludeResourceTags);
        }

        if (!this.grid && !this.isClone && !this.isEdit) {
            this.grid = new Grid();
            this.grid.name = null;
            this.mutedObjects = 0;
            this.acknowledgedObjects = 0;
            this.grid.sort_by = "status";
            this.grid.all_resource_tags = 0;
            this.grid.display_detailed_status = 1;
            this.selectedStatuses = _.map(this.statusList, "id");
            this.grid.fit_screen = true;
            this.grid.display_overlay = 1;
            this.grid.show_info_btm = 0;
        }

        this.loading = false;
    }

    ngOnInit() {
        this.userService
            .getCurrentUser()
            .pipe(take(1))
            .subscribe(user => {
                this.isAdmin = !!user.is_admin;
                this.isZixiAdmin = !!user.is_zixi_admin;
                this.isZixiSupportWrite = !!user.is_zixi_support_write;
            });

        this.tourService.initialize(this.tourSteps);
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.grid.name,
            public: this.grid.public || false,
            rows: this.grid.rows,
            cols: this.grid.cols,
            feeders: (this.type !== "thumbnails" && this.grid.feeders) || false,
            clusters: (this.type !== "thumbnails" && this.grid.clusters) || false,
            broadcasters: (this.type !== "thumbnails" && this.grid.broadcasters) || false,
            receivers: (this.type !== "thumbnails" && this.grid.receivers) || false,
            zecs: (this.type !== "thumbnails" && this.grid.zecs) || false,
            sources: (this.type !== "thumbnails" && this.grid.sources) || false,
            channels: (this.type !== "thumbnails" && this.grid.channels) || false,
            targets: (this.type !== "thumbnails" && this.grid.targets) || false,
            thumbnails: this.type === "thumbnails",
            all_resource_tags: this.grid.all_resource_tags,
            name_filter_include: this.grid.name_filter_include,
            name_filter_exclude: this.grid.name_filter_exclude,
            include_muted: this.mutedObjects === 0 || this.mutedObjects === 2,
            include_not_muted: this.mutedObjects === 0 || this.mutedObjects === 1,
            include_acknowledged: this.acknowledgedObjects === 0 || this.acknowledgedObjects === 2,
            include_not_acknowledged: this.acknowledgedObjects === 0 || this.acknowledgedObjects === 1,
            cycle_pagination: this.grid.cycle_pagination || false,
            cycle_pagination_interval: this.grid.cycle_pagination_interval || 0,
            fit_screen: this.grid.fit_screen || false,
            uniform_height: true,
            sort_by: this.grid.sort_by,
            display_detailed_status: this.grid.display_detailed_status || false,
            display_healthscore: this.grid.display_healthscore || false,
            display_bitrate: this.grid.display_bitrate || false,
            display_tr101: this.grid.display_tr101 || false,
            display_latency: this.grid.display_latency || false,
            display_ip: this.grid.display_ip || false,
            display_cpu: this.grid.display_cpu || false,
            display_ram: this.grid.display_ram || false,
            display_version: this.grid.display_version || false,
            display_bandwidth: this.grid.display_bandwidth || false,
            display_overlay: this.grid.display_overlay || false,
            show_info_btm: this.grid.show_info_btm || false,
            include_resource_tag_ids: _.map(this.grid.includeResourceTags, "id"),
            exclude_resource_tag_ids: _.map(this.grid.excludeResourceTags, "id"),
            show_error: _.includes(this.selectedStatuses, "show_error"),
            show_warning: _.includes(this.selectedStatuses, "show_warning"),
            show_ok: _.includes(this.selectedStatuses, "show_ok"),
            show_pending: _.includes(this.selectedStatuses, "show_pending"),
            show_disabled: _.includes(this.selectedStatuses, "show_disabled"),
            thumbnails_refresh_interval: this.thumbnailRefreshSeconds * 1000
        };

        if (this.isEdit) {
            const result = await this.gs.updateGrid(this.grid, model);
            if (result) {
                this.saving = false;
                this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid, this.grid.id]);
            } else this.saving = false;
        } else {
            const result = await this.gs.addGrid(model);
            if (result) {
                this.saving = false;
                this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid, result.id]);
            } else this.saving = false;
        }
    }

    cancel() {
        this.location.back();
    }

    // Error Code
    infoHasErrors(form: NgForm) {
        if (form.form.controls.name && form.form.controls.name.invalid === true) return true;
        else return false;
    }

    filterHasErrors(form: NgForm) {
        if (form.form.controls.status && form.form.controls.status.invalid === true) return true;
        else return false;
    }

    displayHasErrors(form: NgForm) {
        if (
            form.form.controls.cycle_pagination_interval &&
            form.form.controls.cycle_pagination_interval.invalid === true
        )
            return true;
        else return false;
    }

    // Scroll Spy Code
    onSectionChange(section: string) {
        this.currentSection = section;
    }

    scrollTo(section: string) {
        document.querySelector("#" + section).scrollIntoView({ behavior: "smooth", block: "start" });
    }
}
