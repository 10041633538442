import { Injectable } from "@angular/core";
import { InferredRequests } from "@zixi/zen-rpc";
import { ZenApiService } from "../../../services/zen-rpc-service";

@Injectable({
    providedIn: "root"
})
export class GridVisualizationService {
    constructor(private zenApiService: ZenApiService) {}

    async getGridVisualizationList() {
        const result = await this.zenApiService.client.customVisualization.getList();
        if (result.status !== 200) {
            throw new Error(result.body.error);
        }
        return result.body.result;
    }

    async createGridVisualization(body: InferredRequests["customVisualization"]["create"]["body"]) {
        const result = await this.zenApiService.client.customVisualization.create({
            body
        });
        if (result.status !== 200) {
            throw new Error(result.body.error);
        }
        return result.body.result;
    }

    async updateGridVisualization(id: number, body: InferredRequests["customVisualization"]["update"]["body"]) {
        const result = await this.zenApiService.client.customVisualization.update({
            params: { id },
            body
        });
        if (result.status !== 200) {
            throw new Error(result.body.error);
        }
        return result.body.result;
    }
}
