<div class="panel-container">
    <!-- Table -->
    <div id="list-panel" class="list-panel pb-0" #listPanel>
        <div class="list-container overflow-y-hidden">
            <app-table-list
                [tableName]="'grid'"
                [displayTableName]="'GRIDS' | translate"
                [data]="grids$ | async"
                [(tableSchema)]="tableColumnsSchema"
                (rowSelected)="selectRow($event)"
                [showSelectionCheckbox]="false"
                [showReport]="false"
                [refreshing]="loading"
            >
                <!-- Additional Buttons -->
                <div class="form-group mb-0">
                    <button
                        zmid="add-service"
                        type="button"
                        class="btn btn-outline-primary me-2"
                        routerLink="/{{ urls.grids }}/{{ urls.grid }}/new"
                        title="{{ 'ADD' | translate }} {{ 'GRID' | translate }}"
                    >
                        <fa-icon icon="plus" size="sm"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1">{{ "ADD" | translate }} {{ "GRID" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-secondary" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1">{{ "REFRESH" | translate }}</span>
                    </button>
                </div>
            </app-table-list>
        </div>
    </div>
</div>
