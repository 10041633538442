import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";

import { Constants } from "../../constants/constants";
import { GridsComponent } from "./grids.component";
import { GridFormComponent } from "./grid-form/grid-form.component";
import { GridGroupFormComponent } from "./grid-group-form/grid-group-form.component";
import { GridDetailComponent } from "./grid-detail/grid-detail.component";
import { GridGroupDetailComponent } from "./grid-group-detail/grid-group-detail.component";
import { GridListComponent } from "./grid-list/grid-list.component";
import { GridGroupListComponent } from "./grid-group-list/grid-group-list.component";
import { GridVisualizationComponent } from "./grid-visualization/grid-visualization.component";

const gridRoutes: Routes = [
    {
        path: Constants.urls.grids + "/" + Constants.urls.grid + "/new",
        component: GridFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.GRIDS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/CwB5Kg"
                },
                {
                    menu: "HELP_MENU.CREATING_A_GRID",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/IgB5Kg"
                }
            ]
        }
    },
    {
        path: Constants.urls.grids + "/" + Constants.urls.grid + "/:id",
        component: GridDetailComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.GRIDS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/CwB5Kg"
                },
                {
                    menu: "HELP_MENU.VIEWING_STATUS_GRID_DISPLAY",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/xIEiPg"
                },
                {
                    menu: "HELP_MENU.VIEWING_THUMBNAIL_GRID_DISPLAY",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/xgBOPg"
                }
            ]
        }
    },
    {
        path: Constants.urls.grids + "/" + Constants.urls.grid + "/:id/:action",
        component: GridFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.GRIDS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/CwB5Kg"
                },
                {
                    menu: "HELP_MENU.CREATING_A_GRID",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/IgB5Kg"
                }
            ]
        }
    },
    {
        path: Constants.urls.grids + "/" + Constants.urls.grid_group + "/new",
        component: GridGroupFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.GRIDS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/CwB5Kg"
                },
                {
                    menu: "HELP_MENU.CREATING_A_GRID_GROUP",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/KYB8Kg"
                }
            ]
        }
    },
    {
        path: Constants.urls.grids + "/" + Constants.urls.grid_group + "/:id",
        component: GridGroupDetailComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.GRIDS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/CwB5Kg"
                },
                {
                    menu: "HELP_MENU.VIEWING_GRID_GROUPS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/VwFjPg"
                }
            ]
        }
    },
    {
        path: Constants.urls.grids + "/" + Constants.urls.grid_group + "/:id/:action",
        component: GridGroupFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.GRIDS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/CwB5Kg"
                },
                {
                    menu: "HELP_MENU.CREATING_A_GRID_GROUP",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/KYB8Kg"
                }
            ]
        }
    },
    {
        path: Constants.urls.grids,
        component: GridsComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: Constants.urls.grid_list,
                component: GridListComponent
            },
            {
                path: Constants.urls.group_list,
                component: GridGroupListComponent
            },
            {
                path: Constants.urls.grids_visualization,
                component: GridVisualizationComponent
            }
        ],
        data: {
            help: [
                {
                    menu: "HELP_MENU.GRIDS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/CwB5Kg"
                }
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(gridRoutes)],
    exports: [RouterModule]
})
export class GridsRouting {}
