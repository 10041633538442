import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subscription, interval, firstValueFrom } from "rxjs";

import { Constants } from "../../../constants/constants";
import { GridsService } from "../grids.service";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { Grid } from "../grid";
import { UsersService } from "../../account-management/users/users.service";
import { KeyMap, User } from "src/app/models/shared";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "../../../components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxIconComponent } from "src/app/components/shared/zx-icon/zx-icon.component";
import { assignIconInputsFactory } from "src/app/components/shared/zx-icon/zx-icon.table-adapter";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";

@Component({
    selector: "app-grid-list",
    templateUrl: "./grid-list.component.html"
})
export class GridListComponent implements OnInit, OnDestroy {
    urls = Constants.urls;
    refreshing = false;
    loading = true;
    grids: Grid[] = [];
    user: User;

    private gridsSubscription: Subscription;
    private gridsRefreshSubscription: Subscription;
    private userSubscription: Subscription;

    private gridsBS$ = new BehaviorSubject<Grid[]>([]);

    private gridTypes: Record<string, string> = {
        feeders: this.translate.instant("FEEDERS"),
        clusters: this.translate.instant("CLUSTERS"),
        broadcasters: this.translate.instant("BROADCASTERS"),
        receivers: this.translate.instant("RECEIVERS"),
        sources: this.translate.instant("SOURCES"),
        thumbnails: `${this.translate.instant("SOURCE")} ${this.translate.instant("THUMBNAILS")}`,
        channels: this.translate.instant("CHANNELS"),
        targets: this.translate.instant("TARGETS"),
        zecs: this.translate.instant("ZEC")
    };

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            visible: true,
            width: 240,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                grid => (grid as unknown as KeyMap<Grid>).name,
                grid => (grid as unknown as KeyMap<Grid>).name,
                () => true
            ),
            sortBy: (grid: KeyMap<Grid>) => grid.name,
            textValue: (grid: KeyMap<Grid>) => grid.name
        },
        {
            header: this.translate.instant("PUBLIC"),
            columnDef: "public",
            width: 80,
            visible: true,
            component: ZxIconComponent,
            assignComponentsInputs: assignIconInputsFactory(
                () => "check",
                () => "md",
                grid => !!(grid as unknown as KeyMap<Grid>)?.public
            ),
            sortBy: (grid: KeyMap<Grid>) => grid?.public
        },
        {
            header: this.translate.instant("TYPES"),
            columnDef: "types",
            width: 340,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                grid => this.getTypesColumnText(grid as unknown as KeyMap<Grid>),
                grid => this.getTypesColumnText(grid as unknown as KeyMap<Grid>),
                () => true
            )
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 105,
            align: "right",
            visible: true,
            stickyToLast: true,
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory<Grid, Promise<void>>({
                canEditCallBack: grid => this.canEditGrid(grid),
                canCloneCallBack: grid => this.canEditGrid(grid),
                canDeleteCallBack: grid => this.canEditGrid(grid),
                editRef: grid => this.editGrid(grid.id),
                cloneRef: grid => this.cloneGrid(grid.id),
                deleteRef: grid => this.deleteGrid(grid)
            })
        }
    ];

    constructor(
        private router: Router,
        private gs: GridsService,
        private us: UsersService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService
    ) {
        // Set Title
        this.titleService.setTitle(this.translate.instant("GRIDS"));
    }

    ngOnInit() {
        this.loading = true;

        this.gridsSubscription = this.gs.grids.subscribe(grids => {
            this.grids = grids;
            if (this.grids) {
                this.prepTableData();
                this.loading = false;
            }
        });

        this.userSubscription = this.us.user.subscribe(user => {
            this.user = user;
        });

        this.gs.refreshGrids(true);

        // Start Auto Refresh
        this.startGridsRefresh();
    }

    ngOnDestroy() {
        if (this.gridsSubscription) this.gridsSubscription.unsubscribe();
        if (this.userSubscription) this.userSubscription.unsubscribe();
        this.stopGridsRefresh();
    }

    startGridsRefresh(): void {
        this.gridsRefreshSubscription = interval(60000).subscribe(() => {
            this.gs.refreshGrids(false);
        });
    }

    stopGridsRefresh(): void {
        if (this.gridsRefreshSubscription) this.gridsRefreshSubscription.unsubscribe();
    }

    canEditGrid(grid: Grid): boolean {
        return ((this.user.is_admin && grid.public) || this.user.id === grid.user_id) as boolean;
    }

    editGrid(id: number): void {
        this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid, id, "edit"]);
    }

    cloneGrid(id: number): void {
        this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid, id, "clone"]);
    }

    async deleteGrid(grid: Grid): Promise<void> {
        await this.modalService.confirm(
            "DELETE",
            "GRID",
            async () => {
                const id = grid.id;
                const result = await this.gs.deleteGrid(grid.id);
                if (result) {
                    this.mixpanelService.sendEvent("delete grid", { id });
                    this.gs.refreshGrids(true);
                } else {
                    return false;
                }
            },
            grid.name
        );
    }

    selectRow(grid: Grid): void {
        this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid, grid.id]);
    }

    async refresh(): Promise<void> {
        this.refreshing = true;
        await firstValueFrom(this.gs.refreshGrids(true));
        this.refreshing = false;
    }

    get grids$() {
        return this.gridsBS$;
    }

    private prepTableData(): void {
        if (this.grids) {
            this.gridsBS$.next([...this.grids]);
        }
    }

    getTypesColumnText(grid: Grid): string {
        const resultsArray: Array<string> = [];

        for (const key in this.gridTypes) {
            if (grid[key]) {
                resultsArray.push(this.gridTypes[key]);
            }
        }

        return resultsArray.join(", ");
    }
}
