import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    useNodesState,
    useEdgesState,
    Controls,
    Node,
    Background,
    BackgroundVariant,
    Panel,
    ReactFlowInstance,
    ReactFlowJsonObject
} from "reactflow";
import { DRAG_EVENT_DATA_KEY } from "../sidebar/sidebar.component";
import { VisualizationNode, VisualizationObject, VISUALIZATION_NODE_TYPE } from "./visualization-node";
import shortid from "shortid";

const nodeTypes = {
    [VISUALIZATION_NODE_TYPE]: VisualizationNode
};

export function DropContainer({
    flowJson,
    saveFlowCallback
}: {
    flowJson: ReactFlowJsonObject<Node<VisualizationObject>, any> | null;
    saveFlowCallback: (flow: ReactFlowJsonObject<Node<VisualizationObject>, any>) => void;
}) {
    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance<Node<VisualizationObject>, any>>(null);

    useEffect(() => {
        if (!flowJson) {
            return;
        }
        if (flowJson.nodes) setNodes(flowJson.nodes);
        if (flowJson.edges) setEdges(flowJson.edges);
    }, []);

    const onConnect = useCallback(params => setEdges(eds => addEdge(params, eds)), []);

    const onDragOver = useCallback(event => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);

    const onDrop = useCallback(
        (event: React.DragEvent) => {
            event.preventDefault();
            const visualizationObjectData = event.dataTransfer.getData(DRAG_EVENT_DATA_KEY);
            if (!visualizationObjectData) {
                return;
            }
            const { id, type, name, status, detailsRoute } = JSON.parse(visualizationObjectData) as VisualizationObject;

            const newNode: Node<VisualizationObject> = {
                id: shortid.generate(),
                type: VISUALIZATION_NODE_TYPE,
                position: reactFlowInstance.screenToFlowPosition({
                    x: event.clientX,
                    y: event.clientY
                }),
                data: {
                    id,
                    type,
                    name,
                    status,
                    detailsRoute
                }
            };
            setNodes(nds => nds.concat(newNode));
        },
        [reactFlowInstance]
    );

    const onSave = useCallback(() => {
        saveFlowCallback(reactFlowInstance.toObject());
    }, [reactFlowInstance]);

    return (
        <ReactFlowProvider>
            <div className="drop-container" ref={reactFlowWrapper}>
                <ReactFlow
                    nodes={nodes}
                    nodeTypes={nodeTypes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onInit={setReactFlowInstance}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    fitView
                >
                    <Controls />
                    <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
                    <Panel position="top-right">
                        <button onClick={onSave}>save</button>
                    </Panel>
                </ReactFlow>
            </div>
        </ReactFlowProvider>
    );
}
